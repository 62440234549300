import { CommonModule, Location } from '@angular/common';
import { Component, inject, OnInit, signal, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdsCallControl, AdsEquipmentOnSite } from 'models';
import { firstValueFrom, Observable } from 'rxjs';
import { HeaderService } from 'shared/header/header.service';
import { WorkOrderService } from './work-order-service';
import { MatDialog } from '@angular/material/dialog';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { WorkOrderMobileComponent } from './work-order-mobile/work-order-mobile.component';
import { WorkOrderDesktopComponent } from './work-order-desktop/work-order-desktop.component';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { statusColors } from 'app/utils/call-control';

@Component({
  selector: 'ads-work-order',
  standalone: true,
  imports: [WorkOrderMobileComponent, CommonModule, WorkOrderDesktopComponent],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
})
export class WorkOrderComponent implements OnInit, OnDestroy {
  searchText = '';
  workOrderDetails = signal<AdsCallControl>({});
  equipmentOnSite: Observable<AdsEquipmentOnSite[]> | undefined;
  workOrder = '';
  readonly dialog = inject(MatDialog);

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly workOrderService: WorkOrderService,
    protected readonly layoutNavService: LayoutNavService,
  ) {
    this.headerService.backButtonOverride = () => {
      this.location.back();
    };
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.headerService.titleAnnex()) {
      this.headerService.titleAnnex.set(undefined);
    }
  }

  init = async () => {
    const paramMap = await firstValueFrom(this.route.paramMap);
    this.workOrder = paramMap.get('work-order') ?? '';
    if (this.workOrder?.length > 0) {
      this.workOrderDetails.set(window.history.state.call as AdsCallControl);

      const details = this.workOrderDetails();
      if (details.masterAccount) {
        this.equipmentOnSite = this.workOrderService.getEquipmentOnSite(details.nbr);

        if (details.laststatusname && !this.layoutNavService.isDesktopView()) {
          this.headerService.title.set(this.workOrder);
          this.headerService.titleAnnex.set({
            text: details.laststatusname,
            backgroundColor: statusColors.get(details.laststatusname ?? 'Started') ?? '',
            color: details.laststatusname === 'Scheduled' ? 'white' : '#242424',
            border: details.laststatusname === 'Scheduled' ? '1px solid white' : '',
          });
        }
      }
    }
  };

  openTicketNotes = async () => {
    this.workOrderService.getTicketNotes(this.workOrderDetails().ticketRecId).subscribe((notes) => {
      if (notes.noteInfo.length > 0) {
        this.dialog.open(TicketDialogComponent, {
          data: notes.noteInfo[0],
        });
      }
    });
  };

  openPhone = () => {
    if (this.workOrderDetails()?.phone) {
      window.location.href = 'tel:' + this.workOrderDetails().phone;
    }
  };

  openMap = async () => {
    if (this.workOrderDetails()?.address && (this.workOrderDetails()?.address ?? '').length > 0) {
      window.open('https://maps.google.com/maps?q=' + this.workOrderDetails().address, '_blank');
    }
  };

  openEquipmentOnSite = async () => {
    this.workOrderService.getEquipmentOnSiteUrl(this.workOrderDetails().workorder).subscribe((url) => {
      window.open(encodeURI(url), '_blank');
    });
  };
}
